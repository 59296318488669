import { useState } from "react";
import * as React from "react";
import {
    CheckIcon,
    EditIcon,
    MoveIcon,
    PauseIcon,
    StartIcon,
    StopIcon,
    XIcon,
} from "../../assets/icons";
import {
    padWithLeadingZeros,
    seperateDate,
} from "../../utilities/common";
import {
    changeName,
    controlLights,
    createSwatch,
    endSwatch,
    moveStopwatch,
    toggleSwatch,
} from "../../services/Tables";
import { toast } from "react-toastify";
import { useOutletContext } from "react-router-dom";
import { Props, Timestamp } from "../../utilities/types";
import useTimer from "../../hooks/useTimer";

const Stopwatch = ({ table, getTables, tables }: Props) => {
    const stoppedTime: Timestamp = {
        hours: table.active_stopwatch[0]?.hours || 0,
        minutes: table.active_stopwatch[0]?.minutes || 0,
        seconds: table.active_stopwatch[0]?.seconds || 0,
    };

    //if the status is paused, get the initial time, if it's not, get the time since the last update
    const time: Timestamp =
        table.active_stopwatch[0]?.status == "paused"
            ? stoppedTime
            : seperateDate(
                table.active_stopwatch[0]?.updated_at || new Date(),
                stoppedTime
            );


    const {
        isActive,
        time: { hours, minutes, seconds },
        startTimer,
        pauseTimer,
        resetTimer,
    } = useTimer(table.active_stopwatch[0]?.status === "active", time);

    const [flag, setFlag] = useState(true);
    const [startLoading, setStartLoading] = useState(false);
    const [stopLoading, setStopLoading] = useState(false);
    const [editName, setEditName] = useState(false);
    const user: any = useOutletContext();
    const nameChange: any = React.useRef();



    const turnLightOff = () => {
        controlLights({
            device_id: table.light.device_id,
            local_key: table.light.local_key,
            ip_address: table.light.ip_address || "Auto",
            action: "off",
        }).catch(err => {
            toast.warn("Error turning off the light");
            console.error(err);
        });
    };

    const turnLightOn = () => {
        controlLights({
            device_id: table.light.device_id,
            local_key: table.light.local_key,
            ip_address: table.light.ip_address || "Auto",
            action: "on",
        }).catch(err => {
            toast.warn("Error turning on the light");
            console.error(err);
        });
    };
    const handleStart = async () => {
        setStartLoading(true);

        try {
            if (table.active_stopwatch[0] === undefined && flag) {
                // Start a new stopwatch
                const res = await createSwatch(table.id);

                if (res.error) throw res.error;
                toast.info("Stopwatch started!");
            } else {
                // Toggle the existing stopwatch
                const payload = {
                    hours: hours,
                    minutes: minutes,
                    seconds: seconds,
                };

                const res = await toggleSwatch(table.active_stopwatch[0].id, payload);

                if (res.error) throw res.error;
                toast.info("Stopwatch updated");
            }

            isActive ? turnLightOff() : turnLightOn();
            // Common logic for both cases
            isActive ? pauseTimer() : startTimer();
        } catch (error: any) {
            toast.error(error?.message);
            pauseTimer();
        } finally {
            await getTables();
            setStartLoading(false);
        }
    };

    const handleNameChange = async () => {
        if (nameChange.current?.value.length === 0) {
            toast.error("Field cannot be empty");
            return;
        }
        try {
            const res = await changeName(table.id, {
                name: nameChange.current?.value,
            });
            if (res.error) throw res.error;
            toast.success("Name changed successfully");
        } catch (error: any) {
            console.log(error.response);
            toast.error("Something went wrong!");
        } finally {
            await getTables();
            setEditName(false);
        }
    };

    const handleReset = async () => {
        const confirmed = window.confirm("Are you sure?");

        const payload = {
            hours: hours,
            minutes: minutes,
            seconds: seconds,
        };
        if (confirmed) {
            try {
                let data;
                setStopLoading(true);
                table.active_stopwatch[0] &&
                    (data = await endSwatch(table.active_stopwatch[0].id, payload));

                if (data.error) throw data.error;

                toast.info(
                    "Успешно стопирана. За наплата: " +
                    data?.data.hours +
                    " час/а и " +
                    data?.data.minutes +
                    " минути",
                    { autoClose: 10000, bodyClassName: "font-semibold" }
                );
                turnLightOff();
            } catch (error: any) {
                toast.error(error?.message);
            } finally {
                setStopLoading(false);
                resetTimer();
                getTables();
            }
        } else {
            getTables();
        }
    };

    // const resetStopwatch = () => {
    //   setIsActive(false);
    //   setFlag(true);
    //   setSeconds(0);
    //   setMinutes(0);
    //   setHours(0);
    // };

    const handleMoveTable = async (id: number) => {
        const confirmed = window.confirm("Are you sure?");

        const payload = {
            table_id: id,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
        };
        if (confirmed) {
            try {
                if (table.active_stopwatch[0] !== undefined) {
                    const res = await moveStopwatch(
                        table.active_stopwatch[0].id,
                        payload
                    );
                    if (res.error) throw res.error;
                    resetTimer()
                } else {
                    throw new Error("Stopwatch is not active");
                }
                toast.success("Success");
            } catch (error: any) {
                toast.error(
                    error?.message || "There was an error moving the stopwatch"
                );
            } finally {
                await getTables();
            }
        }
    };

    return (
        <div className="dark:bg-neutral-800 bg-neutral-100 w-11/12 mx-auto sm:w-full flex flex-col items-center p-4 rounded-lg shadow flex-shrink-0">
            <div className="flex items-center gap-3">
                {!editName ? (
                    <h1 className="dark:text-white font-medium text-xl">{table.name}</h1>
                ) : (
                    <input
                        ref={nameChange}
                        defaultValue={table.name}
                        onKeyDown={(e) => {
                            e.key === "Enter" && handleNameChange();
                        }}
                        onChange={(e) => {
                            e.target.style.width = e.target.value.length + 2 + "ch";
                        }}
                        style={{ width: table.name.length + 2 + "ch" }}
                        className={`focus:ring-0 bg-neutral-200 dark:bg-neutral-900 rounded-lg outline-none text-xl font-medium px-2`}
                    />
                )}

                {user.user_roles.role_name === "ADMIN" ? (
                    <div className="flex gap-2 items-center">
                        {!editName ? (
                            <button
                                onClick={() => {
                                    setEditName(true);
                                }}
                            >
                                <EditIcon />
                            </button>
                        ) : (
                            <>
                                <button onClick={handleNameChange}>
                                    <CheckIcon />
                                </button>
                                <button
                                    onClick={() => {
                                        setEditName(false);
                                    }}
                                >
                                    <XIcon />
                                </button>
                            </>
                        )}
                    </div>
                ) : (
                    ""
                )}
            </div>
            <div className="w-11/12 flex justify-center mt-8 bg-neutral-200 dark:bg-neutral-900 dark:text-neutral-300 text-neutral-600 rounded-lg py-8 text-5xl font-semibold">
                {/* <p className="select-none"> */}
                <p>{padWithLeadingZeros(hours, 2)}:{padWithLeadingZeros(minutes, 2)}:{padWithLeadingZeros(seconds, 2)}</p>
                {/* </p> */}
            </div>
            <div className="w-11/12 flex justify-between gap-2 mt-4">
                {!isActive ? (
                    <button
                        disabled={startLoading}
                        onClick={handleStart}
                        className="w-1/2 bg-success text-neutral-100 dark:text-neutral-900 hover:bg-green-500 transition-colors py-2 rounded-lg flex items-center gap-2 justify-center font-medium"
                    >
                        {startLoading ? (
                            <span className="loading loading-ring loading-sm"></span>
                        ) : (
                            <StartIcon />
                        )}
                        Start
                    </button>
                ) : (
                    <button
                        disabled={startLoading}
                        onClick={handleStart}
                        className="w-1/2 bg-warning text-neutral-100 dark:text-neutral-900 hover:bg-yellow-400 transition-colors py-2 rounded-lg flex items-center gap-2 justify-center font-medium"
                    >
                        {startLoading ? (
                            <span className="loading loading-ring loading-sm"></span>
                        ) : (
                            <PauseIcon />
                        )}
                        Pause
                    </button>
                )}
                <button
                    disabled={
                        (seconds === 0 && minutes === 0 && hours === 0) || stopLoading
                    }
                    onClick={handleReset}
                    className="w-1/2 disabled:hover:bg-red-700 disabled:bg-red-700 bg-danger text-neutral-100 dark:text-neutral-900 hover:bg-red-600 transition-colors py-2 rounded-lg flex items-center gap-2 justify-center font-medium"
                >
                    {stopLoading ? (
                        <span className="loading loading-ring loading-sm"></span>
                    ) : (
                        <StopIcon />
                    )}
                    End
                </button>
                {!isActive && (seconds !== 0 || minutes !== 0 || hours !== 0) && (
                    <div className="dropdown dropdown-end w-1/2">
                        <label
                            tabIndex={0}
                            className="w-full cursor-pointer bg-neutral-300 hover:bg-neutral-400 dark:hover:bg-neutral-800 transition-colors dark:bg-neutral-900 py-2 rounded-lg flex items-center gap-2 justify-center font-medium"
                        >
                            <MoveIcon />
                            Move
                        </label>
                        <ul
                            tabIndex={0}
                            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 dark:bg-neutral-900 rounded-box w-52"
                        >
                            {tables.map((e) => {
                                if (e.id === table.id) return;
                                return (
                                    <li key={e.id}>
                                        <button
                                            onClick={() => {
                                                handleMoveTable(e.id);
                                            }}
                                            className="hover:bg-neutral-700 hover:text-neutral-200"
                                        >
                                            {e.name}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Stopwatch;

/*
 {!isActive && (seconds !== 0 || minutes !== 0 || hours !== 0) && (
          <button
            onClick={handleMoveTable}
            className="w-1/2 bg-neutral-300 hover:bg-neutral-400 dark:hover:bg-neutral-800 transition-colors dark:bg-neutral-900 py-2 rounded-lg flex items-center gap-2 justify-center font-medium"
          >
            <StopIcon />
            Move
          </button>
        )}*/
