import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";

export interface ScanHistory {
    scan_date: string;
    total_scans: number;
}

export interface QRScan {
    qr_code_id: number;
    name: string;
    history: ScanHistory[];
}

interface ScansChartProps {
    data: QRScan[];
}

const ScansChart = ({ data }: ScansChartProps) => {
    ChartJS.register(...registerables);

    // Create a union of all scan dates across all QRScan histories
    const allDatesSet = new Set<string>();
    data.forEach((qr) =>
        qr.history.forEach((record) => allDatesSet.add(record.scan_date))
    );
    const allDates = Array.from(allDatesSet).sort();

    // Build a dataset for each QRScan
    const datasets = data.map((qr, index) => {
        // Build a mapping for quick lookup
        const historyMap: { [date: string]: number } = {};
        qr.history.forEach((record) => {
            historyMap[record.scan_date] = record.total_scans;
        });

        // For each date in the union, return the total_scans or 0 if not found
        const dataPoints = allDates.map((date) => historyMap[date] || 0);

        // Use HSLA colors for proper transparency
        const hue = (index * 60) % 360;
        const borderColor = `hsl(${hue}, 70%, 50%)`;
        const backgroundColor = `hsla(${hue}, 70%, 50%, 0.2)`;

        return {
            label: qr.name,
            data: dataPoints,
            fill: true,
            backgroundColor,
            borderColor,
            tension: 0.4,
        };
    });

    const chartData = {
        labels: allDates,
        datasets: datasets,
    };

    return (
        <div className="chart-container min-w-0 p-4 w-full bg-white dark:bg-neutral-800 rounded-xl shadow-lg">
            <div className="px-2">
                <h2 style={{ textAlign: "left" }}>Scans Over Time</h2>
                <p
                    style={{ textAlign: "left" }}
                    className="text-neutral-400 dark:text-neutral-500 text-xs font-semibold py-2"
                >
                    Last 7 days
                </p>
            </div>

            <Line
                data={chartData}
                options={{
                    scales: {
                        x: {
                            type: "category",
                            title: {
                                display: true,
                                text: "Date",
                            },
                        },
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: "Total Scans",
                            },
                        },
                    },
                    elements: {
                        line: {
                            tension: 0.4,
                            borderWidth: 2,
                            borderCapStyle: "round",
                            borderJoinStyle: "round",
                        },
                    },
                    plugins: {
                        title: {
                            display: false,
                        },
                        legend: {
                            display: true,
                            position: "top",
                        },
                    },
                }}
            />
        </div>
    );
};

export default ScansChart;

